(function () {
    'use strict';

    var uploadImage = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/gallery/uplpadImage.html',
        controllerAs: 'userCtrl',
        bindings: {
            tag:'=',
            domain:'=',
            uploadPhoto:'&',
            photoAll:'=',
            folderOne:'=',
            status:'=',
            qiniuId:'=',
            choose:'=',
            uploadPhotoItem:'=',
            userId:'=',
            isDuplicateList:'<'
        },

        controller: ['$scope', '$stateParams', '$q', '$localStorage', 'Qinius','PlatformWechatSetting', 'Customer', 'CustomerUploadAble', 'WxJS', 'Theme', 'Gallery','SweetAlert','$rootScope','ImageService','UidService','Convert','$timeout','MessageService',
            function ($scope, $stateParams, $q, $localStorage, Qinius, PlatformWechatSetting, Customer, CustomerUploadAble, WxJS, Theme, Gallery, SweetAlert,$rootScope,ImageService,UidService,Convert,$timeout,MessageService) {
                var vm = this;

                $scope.duplicateList = [];
                $scope.isDuplicateList = false;
                // 成功多少张
                vm.indd = 0;
                vm.errorImageArr = [];
                vm.filesLength = 0;
                var repeatCount = 0;
                var cancelledCount = 0;
                var localIdsArr = [];
                if (window.location.href.indexOf("?") > -1) {
                    var r = window.location.href.split('?')[1];
                    var typeFlag = r.split('&')[0].split('=')[1];
                    var distinguish = decodeURIComponent(typeFlag);
                    if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake') {
                        vm.uploadType = 'wechat';
                    }else if(distinguish == 'toutiaoApp'){
                        vm.uploadType = 'byteDance';
                    }else if(distinguish == 'alipayApp'){
                        vm.uploadType = 'ali';
                    }else if(distinguish == 'jdApp'){
                        vm.uploadType = 'ali';
                    }
                }else{
                    vm.uploadType = 'wechat';
                }
                function addAlertClass() {
                    setTimeout(function () {
                        $(".sweet-alert").addClass('folder-alert').addClass('whiteBg');
                    })
                }
                function removeAlertClass() {
                    setTimeout(function () {
                        $(".sweet-alert").removeClass('folder-alert').removeClass('whiteBg');
                        $(".sa-button-container").removeClass('folder-alert-top')
                        $(".sa-button-container .cancel").removeClass('folder-alert-right')
                    },200)
                }
                if (wx) {
                    var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                    WxJS.get({url: url}, function (config) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: ['chooseImage', 'previewImage', 'uploadImage','getLocalImgData','hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            // console.log('editor: wechat api ready!');
                        });
                        wx.error(function (res) {
                            console.log(res);
                        });
                    }, function (res) {
                        console.log("editor: wx config error");
                    });
                }

                $scope.domains = Qinius.getDomain1();
                vm.tokenTime = 360000;
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });

                vm.storeId = $stateParams.storeId;
                vm.platformSetting = PlatformWechatSetting.get();
                vm.customer = Customer.current();
                $q.all([$scope.domains.$promise]).then(function(){
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    setTimeout(function fn() {
                        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                            vm.token = msg.token;
                            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                        });
                        setTimeout(fn, vm.tokenTime);
                    }, vm.tokenTime);
                    //安卓
                    $scope.$on('clickAndroidUpload', function(event, args) {
                        vm.indd = 0;
                        if(vm.uploadType=='ali'){
                            ap.chooseImage({
                                sourceType: ['camera', 'album'],
                                sizeType: ['original'],
                                count: 9
                            }, function(res){
                                var files = []
                                for (var i = 0; i < res.apFilePaths.length; i++) {
                                    files.push({
                                        localId:res.apFilePaths[i],
                                        blobCover:res.apFilePaths[i],
                                        imgSize:res.tempFiles[i].size
                                    })
                                }
                                vm.indd = 0;
                                vm.errorImageArr = [];
                                vm.filesLength = files.length;
                                $(".save-pages2").removeClass('hide1');

                                angular.forEach(files,function (file1,index1) {
                                    (function (file,index) {
                                        toBase64(file,index)
                                    })(file1,index1)
                                })
                                function toBase64(file,i) {
                                    // 如下演示如何拿到base64格式的数据，可用于上传到服务器端的场景
                                    var image = new Image();
                                    image.crossOrigin = 'anonymous';
                                    image.size = file.imgSize
                                    image.onload = function() {
                                        var canvas = document.createElement('CANVAS');
                                        var context = canvas.getContext('2d');
                                        canvas.height = image.height;
                                        canvas.width = image.width;
                                        context.drawImage(image, 0, 0);
                                        try {
                                            var dataURL = canvas.toDataURL('image/jpeg');
                                            file.blobCover = dataURL;
                                            Convert.downloadImg4AliH5AngUpload7Niu({
                                                base64:dataURL,
                                                type:"gallery"
                                            },function (res) {
                                                uploadGalleries(files,res,i)
                                            })
                                        } catch (e) {
                                            if(i==files.length-1){
                                                updatePic()
                                            }
                                        }
                                        canvas = null;
                                    }
                                    image.src = file.localId;
                                }

                                function uploadGalleries(files,res,i) {
                                    Gallery.upload({
                                        hash: data.hash,
                                        identifiers: res.message,
                                        provider: "QINIU",
                                        fileName: files[i].localId,
                                        imgSize: files[i].size,
                                        folderId: vm.folderOne ? vm.folderOne.id : '',
                                        check: true,
                                        type: vm.uploadType
                                    }, function (resp) {
                                        if (resp.cancelled) {
                                            cancelledCount += 1;
                                        }
                                        if(resp.duplicate && !vm.isDuplicateList){
                                            var _resp1 = angular.copy(resp);
                                            _resp1.identifier= $scope.domain + _resp1.identifier;
                                            $scope.duplicateList.push(_resp1)
                                        }
                                        files[i].loading = true;
                                        files[i].usenum = 0;
                                        files[i].id = resp.id;
                                        files[i].width = resp.width;
                                        files[i].height = resp.height;
                                        files[i].imgsize = resp.imgSize;
                                        files[i].cancelled = resp.cancelled;

                                        if(vm.tag&&vm.tag=='normalEditor'){
                                            files[i].identifier = $scope.domain + resp.identifier;
                                        }else{
                                            files[i].identifier = resp.identifier;
                                        }

                                        vm.indd++;
                                        // 上传成功
                                        if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                            $timeout(function () {
                                                $scope.loadingAllSuccess = true;
                                                updatePic();
                                            });
                                        }

                                    },function () {
                                        var arr = {
                                            localId:files[--i].localId,
                                            blobCover:null
                                        }
                                        vm.errorImageArr.push(files[i]);
                                        if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                            $timeout(function () {
                                                $scope.loadingAllSuccess = true;
                                                updatePic();
                                            });
                                        }
                                    })
                                }
                            });
                            // AlipayJSBridge.call('chooseImage', {
                            //     sourceType: ['camera', 'album'],
                            //     count: 9
                            // }, function (result) {
                            //     var apFilePaths = result.apFilePathsV2 || result.apFilePaths || [];
                            //     if (typeof apFilePaths === 'string') {
                            //         try {
                            //             apFilePaths = JSON.parse(apFilePaths);
                            //         } catch (e) {}
                            //     }
                            //
                            //     if (!apFilePaths.length || !/^https?:/.test(apFilePaths[0])) {
                            //         return;
                            //     }
                            //
                            //     alert(apFilePaths[0])
                            //     var files = []
                            //     for (var i = 0; i < apFilePaths.length; i++) {
                            //         files.push({
                            //             localId:apFilePaths[i],
                            //             blobCover:apFilePaths[i],
                            //             imgSize:apFilePaths[i].size,
                            //         })
                            //     }
                            //
                            // });
                        } else if (vm.uploadType == 'byteDance'){
                            tt.miniProgram.chooseImage({
                                count: 20,
                                success: function(chooseImageRes){
                                    var tempFilePaths = chooseImageRes.tempFiles;
                                    if (tempFilePaths.length > 0) {
                                        files = tempFilePaths;
                                        files.forEach(function(file){
                                            file.blobCover = file.path;
                                        })
                                        detectionSize();
                                    }
                                },
                                fail: function(error){
                                    if (error.errNo == 10200) {
                                        SweetAlert.swal({
                                            title: '请打开相册和相机权限',
                                            text: "",
                                            timer: 2000,
                                            showConfirmButton: false
                                        });
                                    }
                                }
                            });
                            // 检查图片
                            function detectionSize(){
                                vm.higherSize = [];
                                angular.forEach(files, function (item, index) {
                                    if (item.size / 1024 > 20480) {
                                        vm.higherSize.push(index + 1);
                                    }
                                });
                                //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                                if (vm.higherSize.length > 0) {
                                    $scope.bigSizeTip = true;
                                    $scope.higherSizeLen = vm.higherSize.length;
                                    $scope.$digest();
                                } else {
                                    if(files.length > 0){
                                        filterUpload(files);
                                    }
                                }

                                $scope.filterBigSize = function () {
                                    $scope.bigSizeTip = false;
                                    files = files.filter(function (item, index) {
                                        return item.size / 1024 < 20480;
                                    });
                                    if (files.length == 0) {
                                        updatePic();
                                    } else {
                                        filterUpload(files);
                                    }
                                }
                            }
                        } else{

                            /********************************************
                             * 上传图片功能   后台会返回上传图片的日期
                             * @type {boolean}
                             * 图片接口    chooseImage拍照、本地选图
                             ********************************************/
                            //将blob转换为file
                            function blobToFile(blob, fileName) {
                                blob.lastModifiedDate = new Date();
                                blob.name = fileName;
                                return blob;
                            };
                            vm.indd = 0;  //成功的张数
                            vm.errorImageArr = [];  //错误的张数
                            var files = [];
                            // 微信Android选图9张
                            wx.chooseImage({
                                count: 9, // 默认9
                                sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
                                sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                                success: function (res) {
                                    $(".save-pages2").removeClass('hide1');
                                    var localIds = res.localIds;
                                    localIds.forEach(function(localId){
                                        wx.getLocalImgData({
                                            localId: localId,
                                            success: function (res) {
                                                var base64Data = res.localData;
                                                var blob = '';
                                                if (base64Data.indexOf("base64") != -1) {
                                                    blob = dataURLtoBlob(base64Data);
                                                }
                                                else {
                                                    blob = dataURLtoBlob("data:image/jpeg;base64," + base64Data)
                                                }
                                                var file = blobToFile(blob);
                                                files.push(file);
                                                if (files.length == localIds.length) {
                                                    detectionSize();
                                                }
                                            }
                                        });
                                    })
                                },
                                fail: function (res) {
                                    updatePic();
                                }
                            });
                            // 检查图片
                            function detectionSize(){
                                vm.higherSize = [];
                                angular.forEach(files, function (item, index) {
                                    item.imgSize = item.size;
                                    if (item.size / 1024 > 20480) {
                                        vm.higherSize.push(index + 1);
                                    }
                                });
                                //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                                if (vm.higherSize.length > 0) {
                                    $scope.bigSizeTip = true;
                                    $scope.higherSizeLen = vm.higherSize.length;
                                    $scope.$digest();
                                } else {
                                    if(files.length > 0){
                                        filterUpload(files);
                                    }
                                }

                                $scope.filterBigSize = function () {
                                    $scope.bigSizeTip = false;
                                    files = files.filter(function (item, index) {
                                        return item.size / 1024 < 20480;
                                    });
                                    if (files.length == 0) {
                                        updatePic();
                                    } else {
                                        filterUpload(files);
                                    }
                                }
                            }
                        }
                    });

                    /**
                     * iOS上传
                     * 使用自带的上传图片功能  不调用微信的jssdk
                     * 非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
                     * @param $event
                     */
                    window.uploadImageMutiple = function ($event) {
                        $timeout(function () {
                            if(isIPhone6()){
                                uploadVerify(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.customer.id, function (res) {
                                    uploadImageMutipleCb($event)
                                })
                            }else{
                                uploadImageMutipleCb($event)
                            }
                        })
                    };
                });

                function uploadImageMutipleCb($event) {
                    $scope.bigSizeTip = false;
                    var files = [];
                    cancelledCount = 0;
                    for (var i = 0; i < $event.target.files.length; i++) {
                        var file = $event.target.files[i];
                        // image/png, image/jpeg, image/jpg
                        if(file.type =='image/png'||file.type =='image/jpeg'||file.type =='image/jpg'){
                            file.usenum = 0;
                            file.percent = 0;
                            file.blobCover = URL.createObjectURL(file);
                            files.push(file)
                        }
                    }
                    if(files.length == 0){
                        return
                    }

                    vm.higherSize = [];
                    angular.forEach(files, function (item, index) {
                        item.imgSize = item.size;
                        if (item.size / 1024 > 20480) {
                            vm.higherSize.push(index + 1);
                        }
                    });
                    //系统检测到{{}}张大于20M的图片，已自动过滤\n请压缩后再尝试上传！
                    if (vm.higherSize.length > 0) {
                        $scope.bigSizeTip = true;
                        $scope.higherSizeLen = vm.higherSize.length;
                        $scope.$digest();
                    } else {
                        filterUpload(files);
                    }

                    $scope.filterBigSize = function () {
                        $scope.bigSizeTip = false;
                        files = files.filter(function (item, index) {
                            return item.size / 1024 < 20480;
                        });
                        if (files.length == 0) {
                            updatePic();
                        } else {
                            filterUpload(files);
                        }
                    }
                }

                function filterUpload(files) {
                    $(".save-pages2").removeClass('hide1');
                    asyncUpload(files);
                }

                function asyncUpload(files) {
                    vm.uploadPhotoItem = files;
                    vm.indd = 0;
                    vm.errorImageArr = [];
                    $rootScope.$broadcast('uploadStart',true);
                    vm.filesLength = vm.uploadPhotoItem.length;
                    qiniuUpload(vm.uploadPhotoItem[0], vm.uploadPhotoItem, 0);

                }

                // function qiniuUpload(file, files, index_) {
                //     var formData = new FormData();
                //     formData.append('file', file);
                //     formData.append('unique_names', false);
                //     formData.append('key', "gallery-" + UidService.get());
                //     formData.append('token', vm.token);
                //     file.percent = 20;
                //     var reader = new FileReader();
                //     reader.readAsArrayBuffer(file)
                //     reader.onloadend = function(e) {
                //         formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                //         $.ajax({
                //             // cache: true,
                //             type: "POST",
                //             url: 'https://upload.qiniup.com/',
                //             data: formData,
                //             async: true,
                //             contentType: false,
                //             processData: false,
                //             xhr:function(){
                //                 var myXhr = $.ajaxSettings.xhr();
                //                 if(myXhr.upload){ // check if upload property exists
                //                     myXhr.upload.addEventListener('progress',function(e){
                //                         var loaded = e.loaded;                  //已经上传大小情况
                //                         var total = e.total;                      //附件总大小
                //                         var percent = Math.floor(100*loaded/total)+"%";     //已经上传的百分比
                //                         file.percent = parseInt(100*loaded/total)/2;
                //                     }, false); // for handling the progress of the upload
                //                 }
                //                 return myXhr;
                //             },
                //             success: function (data) {
                //                 vm.uploadPhotoItem[index_].blobCover = $scope.domain +data.key + '?imageView2/1/w/150/h/150';
                //                 file.percent = 80;
                //                 if (files.length > Number(index_) + 1) {
                //                     var f = files[index_ + 1];
                //                     qiniuUpload(f, files, index_ + 1);
                //                 }
                //                 assignGalleries(file, files, index_, data)
                //             },
                //             error: function (request) {
                //                 file.state = 'error';
                //                 vm.errorImageArr.push(files[index_]);
                //                 //最后一个失败了的话
                //                 if (files.length == (vm.indd + vm.errorImageArr.length)) {
                //                     $timeout(function () {
                //                         $scope.loadingAllSuccess = true;
                //                         updatePic();
                //                     })
                //                 }else{
                //                     if (files.length > Number(index_) + 1) {
                //                         var f = files[index_ + 1];
                //                         qiniuUpload(f, files, index_ + 1);
                //                     }
                //                 }
                //             }
                //         });
                //     }
                // }
                function qiniuUpload(file, files , index_){
                    if (vm.uploadType == 'byteDance') {
                        tt.miniProgram.uploadFile({
                            url: 'https://upload.qiniup.com/',
                            filePath: file.path,
                            name: 'file',
			                fileType: 'image',
                            formData: {
                                'token': vm.token,
                                'unique_names': false,
                                'key': 'gallery-' + UidService.get()
                            },
                            success: function(res) {
                                var data = JSON.parse(res.data);
                                vm.uploadPhotoItem[index_].blobCover = $scope.domain + data.key + '?imageView2/1/w/150/h/150';
                                file.percent = 80;
                                if (files.length > Number(index_) + 1) {
                                    var f = files[index_ + 1];
                                    qiniuUpload(f, files, index_ + 1);
                                }
                                assignGalleries(file, files, index_, data)
                            },
                            fail: function(error) {
                                file.state = 'error';
                                vm.errorImageArr.push(files[index_]);
                                //最后一个失败了的话
                                if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                    $timeout(function () {
                                        $scope.loadingAllSuccess = true;
                                        updatePic();
                                    })
                                }else{
                                    if (files.length > Number(index_) + 1) {
                                        var f = files[index_ + 1];
                                        qiniuUpload(f, files, index_ + 1);
                                    }
                                }
                            }
                        })
                    }else {
                        ImageService.backendUploadPics(file, $scope.domain, vm.token, function(data, f){
                            vm.uploadPhotoItem[index_].blobCover = $scope.domain +data.key + '?imageView2/1/w/150/h/150';
                            file.percent = 80;
                            if (files.length > Number(index_) + 1) {
                                var f = files[index_ + 1];
                                qiniuUpload(f, files, index_ + 1);
                            }
                            assignGalleries(file, files, index_, data)
                        },function(err){
                            file.state = 'error';
                            vm.errorImageArr.push(files[index_]);
                            //最后一个失败了的话
                            if (files.length == (vm.indd + vm.errorImageArr.length)) {
                                $timeout(function () {
                                    $scope.loadingAllSuccess = true;
                                    updatePic();
                                })
                            }else{
                                if (files.length > Number(index_) + 1) {
                                    var f = files[index_ + 1];
                                    qiniuUpload(f, files, index_ + 1);
                                }
                            }
                        })
                    }

                }

                function serviceUpload(data, file, callback) {
                    Gallery.upload({
                        hash: data.hash,
                        identifiers: data.key,
                        provider: "QINIU",
                        fileName: file.name,
                        imgSize: file.size,
                        width: file.width,
                        height: file.height,
                        folderId: vm.folderOne ? vm.folderOne.id : '',
                        check: true,
                        type: vm.uploadType,
                        userId: vm.userId ? vm.userId : null
                    }, function (resp) {
                        if(resp.duplicate && !vm.isDuplicateList){
                            var _resp1 = angular.copy(resp);
                            _resp1.identifier= $scope.domain + _resp1.identifier;
                            $scope.duplicateList.push(_resp1)
                        }
                        file.percent = 100;
                        file.id = resp.id;
                        file.cancelled = resp.cancelled;
                        vm.indd++;
                        $timeout(function () {
                            file.state = 'success';
                            if (!resp.cancelled) {
                                if(vm.photoAll){
                                    vm.photoAll.push(resp)
                                }
                                if(vm.qiniuId){
                                    resp.identifier = $scope.domain +resp.identifier;
                                    resp.usenum = 0;
                                    resp.loading = 0;
                                    vm.qiniuId.push(resp)
                                }
                            }
                            if(callback){callback()}
                        },1000)
                    }, function (error) {
                        file.state = 'error';
                        vm.errorImageArr.push(file);
                        if(callback){callback()}
                    });
                }


                function assignGalleries(file, files, index_, data) {
                    serviceUpload(data, file,function () {
                        if (files.length == (vm.indd + vm.errorImageArr.length)) {
                            $timeout(function () {
                                $scope.loadingAllSuccess = true;
                                updatePic();
                            })
                        }else{
                            // if (files.length > Number(index_) + 1) {
                            //     var f = files[index_ + 1];
                            //     qiniuUpload(f, files, index_ + 1);
                            // }
                        }
                    });
                }
                $scope.reUpload = function () {
                    $scope.errorImageArr_.forEach(function (item) {
                        item.percent = 0;
                        item.state = null;
                    })
                    asyncUpload($scope.errorImageArr_);
                    $scope.errorImageHide(true);
                    $(".save-pages2").removeClass('hide1');
                }
                // 上传图片后更新图片库/文件夹
                function updatePic() {
                    $scope.isLookErrorImages = false;
                    vm.uploadPhotoItem = [];
                    $(".save-pages2").addClass('hide1');
                    vm.indd = 0;
                    vm.filesLength = 0;
                    $timeout(function () {
                        $rootScope.$broadcast('uoloadFinished',true);
                        //如果没有上传错误图片，关闭提示框
                        if(vm.errorImageArr.length === 0){
                            $scope.loadingAllSuccess = false;
                            if(!$scope.isLookErrorImages){
                                vm.errorImageArr = [];
                            }
                            updateDuplicate()
                        }
                    },2000)
                }
                function updateDuplicate(){
                    if($scope.duplicateList.length>0){
                        MessageService.confirm({
                            title :'提示',
                            cancel :'取消',
                            confirm :'确认',
                            msg :'检测到图库中有'+$scope.duplicateList.length+'张重复图片，是否需要自动去重？',
                            html: '<span class="themecolor">查看重复图片</span>'
                        }, function(res) {
                            if(res == 'htmlClick'){
                                $scope.isDuplicateList = true;
                            }else{
                                var ids = [];
                                $scope.duplicateList.forEach(function (item){
                                    ids.push(item.id)
                                })
                                Gallery.batchDelete(ids, function () {
                                    $rootScope.$broadcast('uoloadDuplicate',$scope.duplicateList);
                                    $scope.duplicateList=[];
                                }, function (error) {
                                    $scope.duplicateList=[];
                                });
                            }
                        },function (){
                            $scope.duplicateList=[];
                        })
                    }
                }
                $scope.duplicateChange = function (text){
                    if(text){
                        var _text = JSON.parse(text);
                        _text.forEach(function (item){
                            for(var i=0;i<$scope.duplicateList.length;i++){
                                if($scope.duplicateList[i].id == item.id){
                                    $scope.duplicateList.splice(i, 1);
                                    break
                                }
                            }
                        })
                        $rootScope.$broadcast('uoloadDuplicate',JSON.parse(text));
                        if($scope.duplicateList.length == 0){
                            $scope.isDuplicateList = false;
                        }
                    }else{
                        $scope.isDuplicateList = false;
                        $scope.duplicateList=[];
                    }
                }
                //关闭上传错误弹窗
                $scope.closeErrorMoadl = function (){
                    var target = event.target;
                    if($(target).hasClass("z-modal") || $(target).hasClass("close-btn") || $(target).hasClass("close-icon1")){
                        $scope.loadingAllSuccess = false;
                    }
                    updateDuplicate()
                }
                //上传进度获取
                $scope.schedule = function () {
                    if(vm.filesLength > 0){
                        return Number(vm.indd)+vm.errorImageArr.length + '/' + vm.filesLength
                    }
                }
                $scope.errorImageArrCb = function () {
                    return vm.errorImageArr.length
                }
                $scope.successImageArrCb = function () {
                    return vm.indd
                }
                $scope.isLookErrorImages = false;
                $scope.lookErrorImages = function () {
                    $scope.isLookErrorImages = true;
                    $scope.loadingAllSuccess = false;
                    $scope.errorImageArr_ = vm.errorImageArr;
                    $scope.errorImageArr_.forEach(function (item) {
                        item.percent = 0;
                    })
                };
                $scope.errorImageHide = function (item) {
                    $scope.isLookErrorImages = false;
                    if(!item){
                        updateDuplicate()
                    }
                }

                function threeInfo(item){
                    $scope.threeError = true;
                    $scope.threeErrorText=item;
                    $timeout(function () {
                        $scope.threeError = false;
                        $scope.threeErrorText="";
                    },1500)
                }
            }]
    };

    angular
        .module('editorApp')
        .component('uploadImage', uploadImage);
})();
